import { AxiosRequestConfig, CancelToken } from 'axios'

export function authConfig(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } as AxiosRequestConfig
}

export function blobConfig(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  } as AxiosRequestConfig
}

export function uploadConfig(token: string, onUploadProgress: (event: any) => void, cancelToken: CancelToken) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    cancelToken,
  }
}
