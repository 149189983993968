import { Route, RouteConfig } from 'vue-router'
import { RouteNames } from './route-details'
import FullPage from '@/views/FullPage.vue'
import Login from '@/views/full-page/Login.vue'
import PasswordRecovery from '@/views/full-page/PasswordRecovery.vue'
import ResetPassword from '@/views/full-page/ResetPassword.vue'
import Signup from '@/views/full-page/Signup.vue'
import SignupConfirmation from '@/views/full-page/SignupConfirmation.vue'
import Main from '@/views/Main.vue'
import { UserStore } from '@/store/modules/user'

function idParamToNumber(route: Route) {
  return { id: Number(route.params.id) }
}

function hasSuperAccess(to: Route, from: Route, next: any) {
  if (!UserStore.hasSuperAccess) next('/main/dashboard')
  else next()
}

function hasAdminAccess(to: Route, from: Route, next: any) {
  if (!UserStore.hasAdminAccess) next('/main/dashboard')
  else next()
}

export const Routes: Array<RouteConfig> = [
  {
    path: '/full-page',
    component: FullPage,
    children: [
      {
        path: '/login',
        name: RouteNames.login,
        component: Login,
      },
      {
        path: '/password-recovery',
        name: RouteNames.passwordRecovery,
        component: PasswordRecovery,
      },
      {
        path: '/reset-password',
        name: RouteNames.resetPassword,
        component: ResetPassword,
      },
      {
        path: '/signup',
        name: RouteNames.signup,
        component: Signup,
      },
      {
        path: '/signup-confirmation',
        name: RouteNames.signupConfirmation,
        component: SignupConfirmation,
      },
    ],
  },
  {
    path: '/main',
    component: Main,
    children: [
      {
        path: 'super',
        component: () => import(/* webpackChunkName: "super-page" */ '@/components/Eterian/RouterComponent.vue'),
        beforeEnter: hasSuperAccess,
        children: [
          {
            path: 'users',
            name: RouteNames.users,
            component: () => import(/* webpackChunkName: "super-page" */ '@/views/pages/SuperUsers.vue'),
          },
          {
            path: 'users/add',
            name: RouteNames.addUser,
            component: () => import(/* webpackChunkName: "super-page" */ '@/views/pages/AddSuperUser.vue'),
          },
          {
            path: 'companies',
            name: RouteNames.companies,
            component: () => import(/* webpackChunkName: "super-page" */ '@/views/pages/Companies.vue'),
          },
          {
            path: 'companies/add',
            name: RouteNames.addCompany,
            component: () => import(/* webpackChunkName: "super-page" */ '@/views/pages/AddCompany.vue'),
          },
          {
            path: 'companies/:id/update',
            name: RouteNames.updateCompany,
            component: () => import(/* webpackChunkName: "super-page" */ '@/views/pages/UpdateCompany.vue'),
            props: idParamToNumber,
          },
        ],
      },
      {
        path: 'dashboard',
        name: RouteNames.dashboard,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Dashboard.vue'),
      },
      {
        path: 'profile',
        name: RouteNames.profile,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Profile.vue'),
      },
      {
        path: 'billing',
        name: RouteNames.billing,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Billing.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'billing/update',
        name: RouteNames.updateCompanyMe,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/UpdateCompanyMe.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'settings',
        name: RouteNames.settings,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Settings.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'settings/update',
        name: RouteNames.updateSettings,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/UpdateSettings.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'operators',
        name: RouteNames.operators,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Operators.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'operators/add',
        name: RouteNames.addOperator,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/AddOperator.vue'),
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'operators/:id/update',
        name: RouteNames.updateOperator,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/UpdateOperator.vue'),
        props: idParamToNumber,
        beforeEnter: hasAdminAccess,
      },
      {
        path: 'patients',
        name: RouteNames.patients,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Patients.vue'),
      },
      {
        path: 'patients/add',
        name: RouteNames.addPatient,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/AddPatient.vue'),
      },
      {
        path: 'patients/:id',
        name: RouteNames.patient,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Patient.vue'),
        props: idParamToNumber,
      },
      {
        path: 'patients/:id/update',
        name: RouteNames.updatePatient,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/UpdatePatient.vue'),
        props: idParamToNumber,
      },
      {
        path: 'exam-types',
        name: RouteNames.examTypes,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/ExamTypes.vue'),
      },
      {
        path: 'exams',
        name: RouteNames.exams,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Exams.vue'),
        props: true,
      },
      {
        path: 'exams/add',
        name: RouteNames.addExam,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/AddExam.vue'),
        props: true,
      },
      {
        path: 'exams/:id',
        name: RouteNames.exam,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/Exam.vue'),
        props: idParamToNumber,
      },
      {
        path: 'exams/:id/update',
        name: RouteNames.updateExam,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/UpdateExam.vue'),
        props: idParamToNumber,
      },
      {
        path: 'exams/:id/reports/add',
        name: RouteNames.addReport,
        component: () => import(/* webpackChunkName: "main" */ '@/views/pages/AddReport.vue'),
        props: idParamToNumber,
      },
    ],
  },
  {
    path: '/*',
    redirect: '/main',
  },
]
