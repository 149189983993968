import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { ICompany, ICompanyUpdate, ICompanyFirstUpdate } from '@/store/interfaces/company-model'
import { IUserPasswordUpdate } from '@/store/interfaces/user-model'
import { ISettings, ISettingsUpdate } from '@/store/interfaces/settings-model'

export const profileApi = {
  async getCompanyMe(token: string) {
    return axios.get<ICompany>(`${apiUrl}/v1/admin/profile/`, authConfig(token))
  },
  async updateCompanyMe(token: string, data: ICompanyUpdate) {
    return axios.put<ICompany>(`${apiUrl}/v1/admin/profile/`, data, authConfig(token))
  },
  async completeCompanyMe(token: string, data: ICompanyFirstUpdate) {
    return axios.put<ICompany>(`${apiUrl}/v1/admin/profile/complete`, data, authConfig(token))
  },
  async getSettingsMe(token: string) {
    return axios.get<ISettings>(`${apiUrl}/v1/admin/profile/settings`, authConfig(token))
  },
  async updateSettingsMe(token: string, data: ISettingsUpdate) {
    return axios.put<ISettings>(`${apiUrl}/v1/admin/profile/settings`, data, authConfig(token))
  },
  async updateUserPassword(token: string, data: IUserPasswordUpdate) {
    return axios.put(`${apiUrl}/v1/admin/profile/user-pwd`, data, authConfig(token))
  },
}
