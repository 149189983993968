import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig, blobConfig } from '@/store/api/configs'
import { IPatient, IPatientCreate, IPatientUpdate } from '@/store/interfaces/patient-model'
import { NotImplementedError } from '../error-handlers'

export const patientApi = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAll(token: string) {
    throw new NotImplementedError()
  },
  async getMulti(token: string, skip = 0, limit = 100) {
    return axios.get<IPatient[]>(`${apiUrl}/v1/admin/patients/?skip=${skip}&limit=${limit}`, authConfig(token))
  },
  async search(token: string, searchString: string) {
    return axios.get<IPatient[]>(`${apiUrl}/v1/admin/patients/search/${searchString}`, authConfig(token))
  },
  async create(token: string, data: IPatientCreate) {
    return axios.post<IPatient>(`${apiUrl}/v1/admin/patients/`, data, authConfig(token))
  },
  async get(token: string, patientId: number) {
    return axios.get<IPatient>(`${apiUrl}/v1/admin/patients/${patientId}`, authConfig(token))
  },
  async update(token: string, patientId: number, data: IPatientUpdate) {
    return axios.put<IPatient>(`${apiUrl}/v1/admin/patients/${patientId}`, data, authConfig(token))
  },
  async delete(token: string, patientId: number) {
    return axios.delete<IPatient>(`${apiUrl}/v1/admin/patients/${patientId}`, authConfig(token))
  },
  async getPatientPrivacy(token: string, patientId: number) {
    return axios.get(`${apiUrl}/v1/admin/patients/privacy/${patientId}`, blobConfig(token))
  },
  async resetPatientPassword(token: string, patientId: number, mustChangePassword = false) {
    return axios.get(
      `${apiUrl}/v1/admin/patients/${patientId}/reset-password?must_change_password=${mustChangePassword}`,
      blobConfig(token)
    )
  },
}
