






import { Vue, Component, Prop } from 'vue-property-decorator'
import AsideMenuItem from '@/components/Eterian/AsideMenuItem.vue'

@Component({
  components: {
    AsideMenuItem,
  },
})
export default class AsideMenuList extends Vue {
  // Properties
  @Prop({ type: Boolean, default: false })
  isSubmenuList!: boolean
  @Prop({ type: Array, default: () => [] })
  menu!: Array<any>

  // Methods
  menuClick(item: any) {
    this.$emit('menu-click', item)
  }
}
