import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from '@/store/interfaces/user-model'
import { NotImplementedError } from '../error-handlers'

export const userApi = {
  async get(token: string, userId: number) {
    return axios.get<IUserProfile>(`${apiUrl}/v1/super/users/${userId}`, authConfig(token))
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAll(token: string) {
    throw new NotImplementedError()
  },
  async getMulti(token: string, skip = 0, limit = 100) {
    return axios.get<IUserProfile[]>(`${apiUrl}/v1/super/users/?skip=${skip}&limit=${limit}`, authConfig(token))
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async create(token: string, data: IUserProfileCreate) {
    throw new NotImplementedError()
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async update(token: string, userId: number, data: IUserProfileUpdate) {
    throw new NotImplementedError()
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async delete(token: string, superUserId: number) {
    throw new NotImplementedError()
  },
  async getSupers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/v1/super/users/?superusers=1`, authConfig(token))
  },
  async createSuper(token: string, data: IUserProfileCreate) {
    return axios.post<IUserProfile>(`${apiUrl}/v1/super/users/`, data, authConfig(token))
  },
  async updateSuper(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/v1/super/users/${userId}`, data, authConfig(token))
  },
  async deleteSuper(token: string, superUserId: number) {
    return axios.delete<IUserProfile>(`${apiUrl}/v1/super/users/${superUserId}`, authConfig(token))
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/v1/super/users/me`, authConfig(token))
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/v1/super/users/me`, data, authConfig(token))
  },
}
