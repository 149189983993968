import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'

export const loginApi = {
  async loginGetToken(username: string, password: string) {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)

    return axios.post(`${apiUrl}/v1/login/access-token`, params)
  },
  async loginTestToken(token: string) {
    return axios.post(`${apiUrl}/v1/login/test-token`, null, authConfig(token))
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/v1/password-recovery/${email}`)
  },
  async resetPassword(token: string, password: string) {
    return axios.post(`${apiUrl}/v1/reset-password/`, {
      token,
      new_password: password,
    })
  },
}
