// This source code is now part of the PHPLint project, see:
// http://cvs.icosaedro.it:8080/viewvc/public/phplint/stdlib/it/icosaedro/web
// Test code also available under the test/ directory of that project.

/**
 * Class for Italian Partita IVA normalization, formatting and checking.
 * @author Umberto Salsi <salsi@icosaedro.it>
 * @version $Date: 2020/01/23 10:35:47 $
 */
export class PartitaIVA {
  /**
   * Normalizes a PI by removing white spaces.
   * @param string cf Raw PI, possibly with spaces.
   * @return string Normalized PI.
   */
  static normalize(cf: string) {
    return cf.replace(/\s/g, '')
  }

  /**
   * Returns the formatted PI. Currently does nothing but normalization.
   * @param string cf Raw PI, possibly with spaces.
   * @return string Formatted PI.
   */
  static format(cf: string) {
    return this.normalize(cf)
  }

  /**
   * Verifies the basic syntax, length and control code of the given PI.
   * @param string pi Raw PI, possibly with spaces.
   * @return string Null if valid, or string describing why this PI must be
   * rejected.
   */
  static validate(pi: string) {
    pi = this.normalize(pi)
    if (pi.length === 0) return 'Empty.'
    else if (pi.length !== 11) return 'Invalid length.'
    if (!/^[0-9]{11}$/.test(pi)) return 'Invalid characters.'
    let s = 0
    for (let i = 0; i < 11; i++) {
      let n = pi.charCodeAt(i) - '0'.charCodeAt(0)
      if ((i & 1) === 1) {
        n *= 2
        if (n > 9) n -= 9
      }
      s += n
    }
    if (s % 10 !== 0) return 'Invalid checksum.'
    return null
  }
}
