import store from '@/store'
import { showSuccessNotification } from '@/utils/notification'
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators'
import { profileApi } from '../api/admin/profile'
import { checkApiError } from '../api/error-handlers'
import { ISettings, ISettingsUpdate } from '../interfaces/settings-model'
import { AppStore } from './app'
import { UserStore } from './user'

export interface ISettingsState {
  data: ISettings
}

@Module({ dynamic: true, store, name: 'settings', namespaced: true })
class Settings extends VuexModule {
  data: ISettings | null = null

  @Mutation
  RESET_STATE() {
    this.data = null
  }

  @Mutation
  protected SET_DATA(payload: ISettings) {
    this.data = payload
  }

  @Action
  async actionGet(): Promise<boolean> {
    try {
      const response = await profileApi.getSettingsMe(UserStore.token)
      if (response) {
        this.context.commit('SET_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionUpdate(payload: ISettingsUpdate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await Promise.all([
        profileApi.updateSettingsMe(UserStore.token, payload),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      if (response[0]) this.context.commit('SET_DATA', response[0].data)
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: 'Impostazioni aggiornate con successo.' })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }
}

export const SettingsStore = getModule(Settings)
