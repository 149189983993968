import Vue from 'vue'
import dayjs from 'dayjs'
import { toTitleCase } from './string'

Vue.filter('formatDate', (value: string) => {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY')
  } else {
    return 'n.d.'
  }
})
Vue.filter('formatDateTime', (value: string) => {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY HH:mm')
  } else {
    return 'n.d.'
  }
})
Vue.filter('formatDatePretty', (value: string) => {
  if (value) {
    return toTitleCase(dayjs(String(value)).format('ddd, DD MMMM YYYY'))
  } else {
    return 'n.d.'
  }
})
Vue.filter('cityProvince', (city: string, province: string) => {
  if (!city || !province) return ''
  return `${city} (${province})`
})
Vue.filter('betweenParenthesis', (main: string, inParenthesis: string) => {
  if (!main) return ''
  if (!inParenthesis) return main
  return `${main} (${inParenthesis})`
})
Vue.filter('showFieldIfMonthlyBilling', (field: string, billingInterval: string) => {
  if (!billingInterval) return field
  if (billingInterval == 'Mensile') return field
  return ''
})
Vue.filter('formatAmount', (amount: number, currency: string) => {
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: currency,
  }).format(amount / 100)
})
