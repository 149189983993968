




























import { Vue, Component, Prop } from 'vue-property-decorator'
import PerfectScrollbar from 'perfect-scrollbar'
import AsideMenuList from '@/components/Eterian/AsideMenuList.vue'
import { AppStore } from '@/store/modules/app'

@Component({
  components: {
    AsideMenuList,
  },
})
export default class AsideMenu extends Vue {
  $refs!: {
    menuContainer: HTMLElement
  }

  // Properties
  @Prop({ type: Array, default: () => [] })
  menu!: Array<any>
  @Prop({ type: Array, default: () => [] })
  menuBottom!: Array<any>

  // Data
  ps: PerfectScrollbar | null = null

  // Computed Data
  get isAsideVisible() {
    return AppStore.isAsideVisible
  }
  get isAsideExpanded() {
    return AppStore.isAsideExpanded
  }
  get isAsideMobileExpanded() {
    return AppStore.isAsideMobileExpanded
  }

  // Vue Hooks
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.menuContainer)
  }

  // Methods
  menuClick(item: any) {
    this.$emit('menu-click', item)
  }
  psUpdate() {
    if (this.ps) {
      this.ps.update()
    }
  }
}
