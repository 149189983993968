


































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FullPage extends Vue {
  // Vue Hooks
  mounted() {
    document.documentElement.classList.remove('has-aside-left', 'has-aside-mobile-transition', 'has-navbar-fixed-top')
    document.documentElement.classList.add('overflow-y-auto')
  }
  destroyed() {
    document.documentElement.classList.remove('overflow-y-auto')
    document.documentElement.classList.add('has-aside-left', 'has-aside-mobile-transition', 'has-navbar-fixed-top')
  }
}
