























































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { UserStore } from '@/store/modules/user'
import { showErrorNotification } from '@/utils/notification'
import { AppStore } from '@/store/modules/app'
import { RoutePaths } from '@/router/route-details'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
  },
})
export default class ResetPassword extends Vue {
  // Data
  password = ''
  confirmPassword = ''

  // Computed Data
  get isLoading() {
    return AppStore.isLoading
  }

  // Vue Hooks
  mounted() {
    this.checkToken()
  }

  // Methods
  checkToken() {
    const token = this.$router.currentRoute.query.token as string
    if (!token) {
      showErrorNotification({
        content: "Nessun token fornito nell'URL, esegui nuovamente la procedura di recovery.",
      })
      this.$router.push(RoutePaths.passwordRecovery())
    } else {
      return token
    }
  }
  async onSubmit() {
    const token = this.checkToken()
    if (token) {
      await UserStore.actionResetPassword({ token, password: this.password })
      this.$router.push('/')
    }
  }
  onCancel() {
    this.$router.push('/')
  }
}
