import { BaseUtilsWithSudoUpdate } from './base'

export interface ICompanyBase {
  name?: string
  surname?: string
  business_name?: string
  address?: string
  zip_code?: string
  city?: string
  province?: string
  country?: string
  vat_number?: string
  fiscal_code?: string
  sdi?: string
  pec?: string
  phone_number?: string
  mobile_number?: string
}

export interface ICompanyCreateBase {
  name?: string
  surname?: string
  business_name?: string
  address: string
  zip_code: string
  city: string
  province: string
  country: string
  vat_number: string
  fiscal_code?: string
  sdi?: string
  pec?: string
  phone_number?: string
  mobile_number?: string
}

export interface ICompanyCreate extends ICompanyCreateBase {
  email: string
  password: string
}

export interface IWebCompanyCreate {
  name: string
  surname: string
  email: string
  password: string
  wants_newsletter: boolean
}

export type ICompanyFirstUpdate = ICompanyCreateBase

export interface ICompanyUpdate {
  name?: string
  surname?: string
  business_name?: string
  address?: string
  zip_code?: string
  city?: string
  province?: string
  country?: string
  sdi?: string
  pec?: string
  phone_number?: string
  mobile_number?: string
}

export interface ISudoCompanyUpdate extends ICompanyUpdate {
  vat_number?: string
  fiscal_code?: string
}

export interface ICompany extends ICompanyBase {
  id: number
  is_completed: boolean
  created_at: string
  updated_at: string
}

class CompanyUtilsImpl extends BaseUtilsWithSudoUpdate {
  createInit(): ICompanyCreate {
    return {
      name: undefined,
      surname: undefined,
      business_name: undefined,
      address: '',
      zip_code: '',
      city: '',
      province: '',
      country: 'Italia',
      vat_number: '',
      fiscal_code: undefined,
      sdi: undefined,
      pec: undefined,
      phone_number: undefined,
      mobile_number: undefined,
      email: '',
      password: '',
    }
  }

  webCreateInit(): IWebCompanyCreate {
    return {
      name: '',
      surname: '',
      email: '',
      password: '',
      wants_newsletter: false,
    }
  }

  updateInit(obj: ICompany | null): ICompanyUpdate {
    if (obj)
      return {
        name: obj.name,
        surname: obj.surname,
        business_name: obj.business_name,
        address: obj.address,
        zip_code: obj.zip_code,
        city: obj.city,
        province: obj.province,
        country: obj.country,
        sdi: obj.sdi,
        pec: obj.pec,
        phone_number: obj.phone_number,
        mobile_number: obj.mobile_number,
      }
    return {}
  }

  firstUpdateInit(obj: ICompany | null): ICompanyFirstUpdate {
    if (obj)
      return {
        name: obj.name,
        surname: obj.surname,
        business_name: obj.business_name,
        address: obj.address ? obj.address : '',
        zip_code: obj.zip_code ? obj.zip_code : '',
        city: obj.city ? obj.city : '',
        province: obj.province ? obj.province : '',
        country: obj.country ? obj.country : '',
        vat_number: obj.vat_number ? obj.vat_number : '',
        fiscal_code: obj.fiscal_code,
        sdi: obj.sdi,
        pec: obj.pec,
        phone_number: obj.phone_number,
        mobile_number: obj.mobile_number,
      }
    return {
      name: undefined,
      surname: undefined,
      business_name: undefined,
      address: '',
      zip_code: '',
      city: '',
      province: '',
      country: '',
      vat_number: '',
      fiscal_code: undefined,
      sdi: undefined,
      pec: undefined,
      phone_number: undefined,
      mobile_number: undefined,
    }
  }

  sudoUpdateInit(obj: ICompany | null): ISudoCompanyUpdate {
    if (obj)
      return {
        ...this.updateInit(obj),
        vat_number: obj.vat_number,
        fiscal_code: obj.fiscal_code,
      }
    return {}
  }

  firstUpdateGetDiff(obj: ICompanyFirstUpdate, refObj: ICompany): ICompanyFirstUpdate {
    const diff = this.firstUpdateInit(refObj)
    this.trimDiff(obj, diff)
    return diff
  }

  firstUpdateCheckHasChanged(obj: ICompanyFirstUpdate, refObj: ICompany): boolean {
    const diff = this.firstUpdateGetDiff(obj, refObj)
    return this.hasValorizedProps(diff)
  }
}

export const CompanyUtils = new CompanyUtilsImpl()
