import {
  required,
  confirmed,
  length,
  email,
  oneOf,
  min,
  min_value,
  max,
  alpha,
  alpha_num,
  digits,
  numeric,
} from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import { Validator } from '@marketto/codice-fiscale-utils'
import { PartitaIVA } from './partita-iva'

/* Secure Password Regex for Validator*/
const strongPwdRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,32})'
)

/* Setup eager mode */
setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Questo campo è richiesto.',
})

extend('email', {
  ...email,
  message: 'Inserire un indirizzo email valido.',
})

extend('confirmed', {
  ...confirmed,
  message: 'Il campo di conferma non coincide.',
})

extend('length', {
  ...length,
  message: 'This field must have 2 options.',
})

extend('oneOf', {
  ...oneOf,
  message: 'Valore non disponibile.',
})

extend('min', {
  ...min,
  message: 'Valore non valido.',
})

extend('min_value', {
  ...min_value,
  message: 'Il valore minimo consentito è {min}.',
})

extend('max', {
  ...max,
  message: 'Valore non valido.',
})

extend('max_len', {
  ...max,
  message: 'Lunghezza massima consentita di {length} caratteri.',
})

extend('alpha', {
  ...alpha,
  message: 'Sono ammessi solo caratteri alfabetici.',
})

extend('alpha_num', {
  ...alpha_num,
  message: 'Sono ammessi solo caratteri alfanumerici.',
})

extend('digits', {
  ...digits,
  message: 'Valore non valido.',
})

extend('numeric', {
  ...numeric,
  message: 'Valore non valido.',
})

extend('fiscal_code', {
  message: 'Il codice fiscale immesso non è corretto.',
  validate: (value) => Validator.codiceFiscale(value).valid,
})

extend('vat_number', {
  message: 'La partita iva immessa non è corretta.',
  validate: (value) => {
    const notValid = PartitaIVA.validate(value)
    if (notValid) return false
    return true
  },
})

extend('secure_password', {
  message:
    'La password deve essere compresa tra 8 e 32 caratteri e deve contenere almeno una minuscola, una maiuscola, un numero ed un carattere speciale.',
  validate: (value) => strongPwdRegex.test(value),
})
