import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IDashboard } from '@/store/interfaces/stat-model'

export const statsApi = {
  async getDashboard(token: string) {
    return axios.get<IDashboard>(`${apiUrl}/v1/admin/stats/dashboard`, authConfig(token))
  },
}
