


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AppStore } from '@/store/modules/app'

@Component({
  components: {
    AsideMenuList: () => import('@/components/Eterian/AsideMenuList.vue'),
  },
})
export default class AsideMenuItem extends Vue {
  // Properties
  @Prop({ type: Object, default: null })
  item!: Record<string, any>

  // Data
  isDropdownActive = false

  // Computed Data
  get componentIs() {
    return this.item.to ? 'router-link' : 'a'
  }
  get hasSubmenuIcon() {
    return this.hasDropdown
  }
  get hasDropdown() {
    return !!this.item.menu
  }
  get submenuIcon() {
    return this.isDropdownActive ? 'minus' : 'plus'
  }
  get itemTo() {
    return this.item.to ? this.item.to : null
  }
  get itemHref() {
    return this.item.href ? this.item.href : null
  }
  get componentTitle() {
    return !this.isAsideExpanded && this.item.label ? this.item.label : null
  }
  get componentClass() {
    const c: Record<string, any> = {
      'has-icon': !!this.item.icon,
      'has-submenu-icon': this.hasSubmenuIcon,
    }

    if (this.item.state) {
      c['is-state-' + this.item.state] = true
      c['is-hoverable'] = true
    }

    return c
  }
  get isAsideExpanded() {
    return AppStore.isAsideExpanded
  }
  get isAsideMobileExpanded() {
    return AppStore.isAsideMobileExpanded
  }

  // Methods
  menuClick() {
    this.$emit('menu-click', this.item)

    if (this.hasDropdown) {
      this.isDropdownActive = !this.isDropdownActive

      if (!this.isAsideMobileExpanded) {
        AppStore.asideStateToggle(true)
      }
    }
  }

  // Watchers
  @Watch('isAsideExpanded')
  onIsAsideExpandedChange(newValue: boolean) {
    if (!newValue) {
      this.isDropdownActive = false
    }
  }
}
