






















































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { UserStore } from '@/store/modules/user'
import { AppStore } from '@/store/modules/app'
import { actionPerformEmailVerification } from '@/store/api/web'
import { RoutePaths } from '@/router/route-details'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
  },
})
export default class Login extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Data
  email = ''
  password = ''

  // Computed Data
  get loginError() {
    return UserStore.loginError
  }
  get isLoading() {
    return AppStore.isLoading
  }

  // Vue Hooks
  async mounted() {
    await this.checkEmailVerificationToken()
  }

  // Methods
  async checkEmailVerificationToken() {
    const token = this.$router.currentRoute.query.evt as string
    if (!token) return
    await actionPerformEmailVerification(token)
    this.$router.push(RoutePaths.login())
  }
  onReset() {
    this.password = ''
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
  }
  async onSubmit() {
    await UserStore.actionLogIn({ username: this.email, password: this.password })
    if (this.loginError) this.onReset()
  }
}
