import store from '@/store'
import { Module, Action, getModule } from 'vuex-module-decorators'
import { IPatient, IPatientCreate, IPatientUpdate } from '../interfaces/patient-model'
import { checkApiError } from '../api/error-handlers'
import { patientApi } from '../api/admin/patients'
import { UserStore } from './user'
import { AppStore } from './app'
import { BaseModuleWithSearch, IModuleMessages } from './base'
import { openPdfResponseOnModal } from '@/utils/file'

export interface IPatientState {
  patients: IPatient[]
  filteredPatients: IPatient[]
}

@Module({ dynamic: true, store, name: 'patient', namespaced: true })
class Patient extends BaseModuleWithSearch<IPatient, IPatientCreate, IPatientUpdate> {
  messages: IModuleMessages = {
    create: 'Paziente inserito con successo.',
    update: 'Paziente modificato con successo.',
    delete: 'Paziente rimosso correttamente.',
  }
  api = patientApi

  @Action
  async actionGetPatientPrivacy(id: number): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await patientApi.getPatientPrivacy(UserStore.token, id)
      openPdfResponseOnModal(response, 'privacy.pdf')
      AppStore.SET_IS_LOADING(false)
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
    }
  }

  @Action
  async actionResetPatientPassword(payload: { id: number; mustChangePassword?: boolean }): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await patientApi.resetPatientPassword(UserStore.token, payload.id, payload.mustChangePassword)
      openPdfResponseOnModal(response, 'password.pdf')
      this.data.forEach((patient) => {
        if (patient.id == payload.id) patient.is_active = true
      })
      this.filteredData.forEach((patient) => {
        if (patient.id == payload.id) patient.is_active = true
      })
      AppStore.SET_IS_LOADING(false)
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
    }
  }
}

export const PatientStore = getModule(Patient)
