




import { Vue, Component } from 'vue-property-decorator'
import { AppStore } from '@/store/modules/app'

@Component
export default class Overlay extends Vue {
  // Computed Data
  get isOverlayVisible() {
    return AppStore.isOverlayVisible
  }

  // Methods
  close() {
    AppStore.overlayToggle()
  }
}
