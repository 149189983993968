type Type = Record<string, any>
type TypeCreate = Record<string, any>
type TypeUpdate = Record<string, any>
type TypeAny = Record<string, any>

export function equals(a: any, b: any): boolean {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
  if (a.prototype !== b.prototype) return false
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => equals(a[k], b[k]))
}

export abstract class BaseUtils {
  abstract createInit(): TypeCreate
  abstract updateInit(obj: Type | null): TypeUpdate

  /**
   * Compare obj and diff undefining diff properties
   * that are equal between the two objects
   */
  protected trimDiff(obj: TypeAny, diff: TypeAny) {
    let k: keyof typeof obj
    for (k in obj) {
      if (obj[k] == diff[k] || (Array.isArray(obj[k]) && obj[k].length == 0)) diff[k] = undefined
      else if (typeof diff[k] === 'number' && obj[k] == '') diff[k] = null
      else if (obj[k] != '' || diff[k] != undefined) diff[k] = obj[k]
      else console.log('trimDiff error')
      // TODO: improve equality validation
    }
  }

  protected hasValorizedProps(obj: TypeAny): boolean {
    let k: keyof typeof obj
    for (k in obj) {
      if (obj[k] != undefined) return true
    }
    return false
  }

  createCleanUndefined(obj: TypeCreate) {
    const ref = this.createInit()
    let k: keyof typeof ref
    for (k in ref) {
      if (ref[k] == undefined && !obj[k]) obj[k] = undefined
    }
  }

  createGetDiff(obj: TypeCreate): TypeCreate {
    const diff = this.createInit()
    this.trimDiff(obj, diff)
    return diff
  }

  createCheckHasChanged(obj: TypeCreate): boolean {
    const diff = this.createGetDiff(obj)
    return this.hasValorizedProps(diff)
  }

  updateGetDiff(obj: TypeUpdate, refObj: Type): TypeUpdate {
    const diff = this.updateInit(refObj)
    this.trimDiff(obj, diff)
    return diff
  }

  updateCheckHasChanged(obj: TypeUpdate, refObj: Type): boolean {
    const diff = this.updateGetDiff(obj, refObj)
    return this.hasValorizedProps(diff)
  }
}

export abstract class BaseUtilsWithSudoUpdate extends BaseUtils {
  abstract sudoUpdateInit(obj: Type | null): TypeUpdate

  sudoUpdateGetDiff(obj: TypeUpdate, refObj: Type): TypeUpdate {
    const diff = this.sudoUpdateInit(refObj)
    this.trimDiff(obj, diff)
    return diff
  }

  sudoUpdateCheckHasChanged(obj: TypeUpdate, refObj: Type): boolean {
    const diff = this.sudoUpdateGetDiff(obj, refObj)
    return this.hasValorizedProps(diff)
  }
}
