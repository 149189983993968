import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { RoutePaths } from './route-details'
import { Routes } from './routes'
import { AppStore } from '@/store/modules/app'
import { UserStore } from '@/store/modules/user'

// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes,
})

const defaultDocumentTitle = 'Referto In Cloud - Il Referto a portata di Click!'

const windowTitleSetter = (to: Route) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const routeGuard = async (to: Route, from: Route, next: Function) => {
  await UserStore.actionCheckLoggedIn()
  if (UserStore.isLoggedIn) {
    if (to.path === RoutePaths.login() || to.path === '/' || to.path === '/main') {
      next(RoutePaths.dashboard())
    } else {
      next()
    }
  } else if (UserStore.isLoggedIn === false) {
    if (to.path === '/' || (to.path as string).startsWith('/main')) {
      next(RoutePaths.login())
    } else {
      next()
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
router.beforeEach((to: Route, from: Route, next: Function) => {
  // NProgress.start()
  routeGuard(to, from, next)
})

router.afterEach((to: Route) => {
  windowTitleSetter(to)
  AppStore.asideMobileStateToggle(false)
  AppStore.overlayToggle(false)
  // NProgress.done()
})

export default router
