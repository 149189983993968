import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { IUserPasswordUpdate, IUserProfile, IUserProfileUpdate } from '../interfaces/user-model'
import { ICompany, ICompanyUpdate, ICompanyFirstUpdate } from '../interfaces/company-model'
import { loginApi } from '../api/login'
import { userApi } from '../api/super/users'
import { companyApi } from '../api/super/companies'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils/local-storage'
import router from '@/router'
import { showInfoNotification, showSuccessNotification, showSnackbarNotification } from '@/utils/notification'
import { AppStore } from './app'
import { checkApiError } from '../api/error-handlers'
import { PatientStore } from './patient'
import { CompanyStore } from './company'
import { SuperUserStore } from './superuser'
import { ExamTypeStore } from './exam-type'
import { OperatorStore } from './operator'
import { ExamStore } from './exam'
import { ReportStore } from './report'
import { RoutePaths } from '@/router/route-details'
import { profileApi } from '../api/admin/profile'
import { StatsStore } from './stats'
import { SettingsStore } from './settings'
import { PaymentStore } from './payment'

export interface IUserState {
  token: string
  userProfile: IUserProfile | null
  companyProfile: ICompany | null
  isLoggedIn: boolean | null
  loginError: boolean
  boundCompany: ICompany | null
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class User extends VuexModule implements IUserState {
  token = ''
  userProfile: IUserProfile | null = null
  companyProfile: ICompany | null = null
  isLoggedIn: boolean | null = null
  loginError = false
  boundCompany: ICompany | null = null

  get hasSuperAccess() {
    return this.userProfile && this.userProfile.is_superuser
  }
  get hasAdminAccess() {
    return this.userProfile && this.userProfile.is_admin && this.userProfile.is_active
  }

  @Mutation
  RESET_STATE() {
    this.token = ''
    this.userProfile = null
    this.companyProfile = null
    this.isLoggedIn = null
    this.loginError = false
    this.boundCompany = null
  }

  @Mutation
  private SET_TOKEN(payload: string) {
    this.token = payload
  }

  @Mutation
  private SET_USER_PROFILE(payload: IUserProfile) {
    this.userProfile = payload
  }

  @Mutation
  private SET_COMPANY_PROFILE(payload: ICompany) {
    this.companyProfile = payload
  }

  @Mutation
  private SET_LOGGED_IN(payload: boolean) {
    this.isLoggedIn = payload
  }

  @Mutation
  private SET_LOGIN_ERROR(payload: boolean) {
    this.loginError = payload
  }

  @Mutation
  private SET_BOUND_COMPANY(payload: ICompany | null) {
    this.boundCompany = payload
  }

  @Action
  async actionLogIn(payload: { username: string; password: string }): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await loginApi.loginGetToken(payload.username, payload.password)
      const token = response.data.access_token
      if (token) {
        saveLocalToken(token)
        this.SET_TOKEN(token)
        this.SET_LOGGED_IN(true)
        this.SET_LOGIN_ERROR(false)
        await this.actionGetUserProfile()
        this.actionRouteLoggedIn()
        showSuccessNotification({ content: 'Bentornato!' })
      } else {
        this.actionLogOut()
      }
    } catch (error) {
      this.SET_LOGIN_ERROR(true)
      checkApiError(error)
      this.actionLogOut()
    } finally {
      AppStore.SET_IS_LOADING(false)
    }
  }

  @Action
  async actionGetUserProfile(): Promise<boolean> {
    try {
      const response = await userApi.getMe(this.token)
      if (response.data) {
        this.SET_USER_PROFILE(response.data)
        this.SET_BOUND_COMPANY(null)
      }
      if (this.hasSuperAccess && this.userProfile?.company_id) {
        await this.actionGetBoundCompany(this.userProfile.company_id)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionGetCompanyProfile(): Promise<boolean> {
    try {
      const response = await profileApi.getCompanyMe(this.token)
      if (response.data) {
        this.SET_COMPANY_PROFILE(response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionUpdateCompanyProfile(payload: ICompanyUpdate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = (
        await Promise.all([
          profileApi.updateCompanyMe(this.token, payload),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ])
      )[0]
      if (response.data) this.SET_COMPANY_PROFILE(response.data)
      AppStore.SET_IS_LOADING(false)
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionCompleteCompanyProfile(payload: ICompanyFirstUpdate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = (
        await Promise.all([
          profileApi.completeCompanyMe(this.token, payload),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ])
      )[0]
      if (response.data) this.SET_COMPANY_PROFILE(response.data)
      AppStore.SET_IS_LOADING(false)
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionGetBoundCompany(id: number): Promise<void> {
    try {
      const response = await companyApi.get(this.token, id)
      if (response.data) {
        this.SET_BOUND_COMPANY(response.data)
      }
    } catch (error) {
      checkApiError(error)
    }
  }

  @Action
  async actionUpdateUserProfile(payload: IUserProfileUpdate): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = (
        await Promise.all([userApi.updateMe(this.token, payload), new Promise((resolve) => setTimeout(resolve, 500))])
      )[0]
      this.SET_USER_PROFILE(response.data)
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: 'Profilo aggiornato con successo.' })
    } catch (error) {
      checkApiError(error)
    }
  }

  @Action
  async actionCheckLoggedIn(): Promise<void> {
    if (!this.isLoggedIn) {
      let token = this.token
      if (!token) {
        const localToken = getLocalToken()
        if (localToken) {
          this.SET_TOKEN(localToken)
          token = localToken
        }
      }
      if (token) {
        const result = await this.actionGetUserProfile()
        if (result) {
          this.SET_LOGGED_IN(true)
        } else {
          this.actionRemoveLogIn()
        }
      } else {
        this.actionRemoveLogIn()
      }
    }
  }

  @Action
  actionRemoveLogIn(): void {
    removeLocalToken()
    this.SET_TOKEN('')
    this.SET_LOGGED_IN(false)
  }

  @Action
  actionLogOut(): void {
    this.actionRemoveLogIn()
    this.actionRouteLogOut()
  }

  @Action
  actionUserLogOut(): void {
    this.actionLogOut()
    showInfoNotification({ content: 'Disconnesso. Arrivederci!' })
  }

  @Action
  actionRouteLogOut(): void {
    if (router.currentRoute.path !== RoutePaths.login()) {
      router.push(RoutePaths.login())
    }
    this.actionResetAllModuleStates()
  }

  @Action
  actionRouteLoggedIn(): void {
    if (router.currentRoute.path === RoutePaths.login() || router.currentRoute.path === '/') {
      router.push(RoutePaths.dashboard())
    }
  }

  @Action
  async actionPasswordRecovery(email: string): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([loginApi.passwordRecovery(email), new Promise((resolve) => setTimeout(resolve, 500))])
      AppStore.SET_IS_LOADING(false)
      showSnackbarNotification(
        {
          content:
            "Se l'indirizzo email fornito appartiene ad un account regolarmente registrato, riceverai una mail contenente le istruzioni per ripristinare la password.",
          actionText: 'OK',
          indefinite: true,
        },
        'is-success'
      )
      this.actionLogOut()
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
    }
  }

  @Action
  async actionResetPassword(payload: { token: string; password: string }): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        loginApi.resetPassword(payload.token, payload.password),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: 'Password aggiornata con successo.' })
      this.actionLogOut()
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
    }
  }

  @Action
  async actionBindToCompany(id: number): Promise<boolean> {
    try {
      const response = await companyApi.bindToCompany(this.token, id)
      if (response) {
        this.SET_BOUND_COMPANY(response.data)
      }
      await this.actionGetUserProfile()
      this.actionResetAllModuleStates()
      router.push(RoutePaths.dashboard())
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionUpdatePassword(payload: IUserPasswordUpdate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        profileApi.updateUserPassword(UserStore.token, payload),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: 'Password modificata con successo.' })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  actionResetAllModuleStates() {
    //AppStore.RESET_STATE()
    CompanyStore.RESET_STATE()
    ExamTypeStore.RESET_STATE()
    ExamStore.RESET_STATE()
    OperatorStore.RESET_STATE()
    PatientStore.RESET_STATE()
    PaymentStore.RESET_STATE()
    ReportStore.RESET_STATE()
    SettingsStore.RESET_STATE()
    StatsStore.RESET_STATE()
    SuperUserStore.RESET_STATE()
  }
}

export const UserStore = getModule(User)
