import store from '@/store'
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators'
import { paymentApi } from '../api/admin/payments'
import { billingApi } from '../api/admin/billing'
import { checkApiError } from '../api/error-handlers'
import { IProductPlan } from '../interfaces/billing-model'
import { IPayment } from '../interfaces/payment-model'
import { UserStore } from './user'

export interface IPaymentState {
  data: IPayment[]
  productPlans: IProductPlan[]
  productPlanDict: Record<string, IProductPlan>
}

@Module({ dynamic: true, store, name: 'payment', namespaced: true })
class Payment extends VuexModule {
  data: IPayment[] = []
  productPlans: IProductPlan[] = []
  productPlanDict: Record<string, IProductPlan> = {}

  @Mutation
  RESET_STATE() {
    this.data = []
    this.productPlans = []
    this.productPlanDict = {}
  }

  @Mutation
  protected SET_DATA(payload: IPayment[]) {
    this.data = payload
  }

  @Mutation
  protected SET_PRODUCT_PLANS(payload: IProductPlan[]) {
    this.productPlans = payload
  }

  @Mutation
  protected SET_PRODUCT_PLAN_DICT(payload: Record<string, IProductPlan>) {
    this.productPlanDict = payload
  }

  @Action
  async actionGetAll(): Promise<boolean> {
    try {
      await this.actionGetPlans()
      const response = await paymentApi.getAll(UserStore.token)
      if (response) {
        this.context.commit('SET_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionGetPlans(): Promise<void> {
    try {
      if (this.productPlans.length > 0) return
      const response = await billingApi.getProductPlans(UserStore.token)
      if (response) {
        this.context.commit('SET_PRODUCT_PLANS', response.data)
      }
      const planDict: Record<string, IProductPlan> = {}
      for (const plan of this.productPlans) planDict[plan.id] = plan
      this.context.commit('SET_PRODUCT_PLAN_DICT', planDict)
    } catch (error) {
      checkApiError(error)
    }
  }
}

export const PaymentStore = getModule(Payment)
