





































































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { CompanyUtils, IWebCompanyCreate } from '@/store/interfaces/company-model'
import { actionRegisterCompany } from '@/store/api/web'
import { RoutePaths } from '@/router/route-details'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    InputFieldWithValidation,
  },
})
export default class Signup extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Data
  isLoading = false
  form: IWebCompanyCreate = CompanyUtils.webCreateInit()
  confirmPassword = ''
  termsAccepted = false

  // Computed Data

  // Vue Hooks
  mounted() {
    this.getQueryEmail()
  }

  // Methods
  getQueryEmail() {
    const email = this.$router.currentRoute.query.email as string
    if (email) {
      this.form.email = email
    }
  }
  async onSubmit() {
    this.isLoading = true
    const result = await actionRegisterCompany(this.form)
    this.isLoading = false
    if (result) {
      this.$router.push(RoutePaths.signupConfirmation())
    }
  }
}
