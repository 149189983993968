






import { Vue, Component, Prop } from 'vue-property-decorator'
import { UserStore } from '@/store/modules/user'

@Component
export default class UserAvatar extends Vue {
  // Properties
  @Prop({ type: String, default: null })
  avatar!: string

  // Computed Data
  get newUserAvatar() {
    if (this.avatar) {
      return this.avatar
    }

    if (this.userAvatar) {
      return this.userAvatar
    }

    let initials: string | undefined
    if (this.userName) {
      initials = this.userName
        .replace(/[^a-zA-Z- ]/g, '')
        .match(/\b\w/g)
        ?.join('')
    }

    return `https://avatars.dicebear.com/api/initials/${initials}.svg`
  }
  get userAvatar() {
    return null
  }
  get userName() {
    if (UserStore.userProfile) {
      return UserStore.userProfile.full_name
    }
    return null
  }
}
