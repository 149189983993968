import store from '@/store'
import { Module, getModule, Action } from 'vuex-module-decorators'
import { IReport, IReportCreate, IReportUpdate } from '../interfaces/report-model'
import { reportApi } from '../api/admin/reports'
import { BaseModule, IModuleMessages } from './base'
import { UserStore } from './user'
import { checkApiError } from '../api/error-handlers'
import { AppStore } from './app'
import { openPdfResponseOnModal } from '@/utils/file'

export interface IReportState {
  reports: IReport[]
}

@Module({ dynamic: true, store, name: 'report', namespaced: true })
class Report extends BaseModule<IReport, IReportCreate, IReportUpdate> {
  messages: IModuleMessages = {
    create: 'Referto inserito con successo.',
    update: 'Referto modificato con successo.',
    delete: 'Referto rimosso correttamente.',
  }
  api = reportApi

  @Action
  async actionGetAllByExamId(examId: number): Promise<boolean> {
    try {
      const response = await this.api.getAllByExamId(UserStore.token, examId)
      if (response) {
        this.context.commit('SET_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDownloadReport(id: number): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await reportApi.download(UserStore.token, id)
      openPdfResponseOnModal(response, 'report.pdf')
      AppStore.SET_IS_LOADING(false)
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
    }
  }
}

export const ReportStore = getModule(Report)
