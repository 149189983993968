import { UserStore } from '@/store/modules/user'
import { RoutePaths } from '@/router/route-details'

export function getMenu(): Array<any> {
  const menu: Array<any> = [
    'Generale',
    [
      {
        to: RoutePaths.dashboard(),
        icon: 'desktop-mac',
        label: 'Home',
      },
    ],
  ]

  // Super User menus
  if (UserStore.hasSuperAccess) {
    menu.push('Superuser', [
      {
        to: RoutePaths.users(),
        icon: 'shield-account',
        label: 'Utenti',
      },
      {
        to: RoutePaths.companies(),
        icon: 'office-building',
        label: 'Aziende',
      },
    ])
  }

  if (UserStore.userProfile?.company_id) {
    const adminMenu: Array<any> = []
    menu.push('Amministrazione')

    // Admin menus
    if (UserStore.hasAdminAccess) {
      adminMenu.push({
        to: RoutePaths.operators(),
        label: 'Operatori',
        icon: 'account-circle',
      })
    }

    // Operators menus
    adminMenu.push(
      {
        to: RoutePaths.patients(),
        label: 'Pazienti',
        icon: 'account-group',
      },
      {
        to: RoutePaths.examTypes(),
        label: "Tipologie d'Esame",
        icon: 'tag-multiple',
      },
      {
        to: RoutePaths.exams(),
        label: 'Esami',
        icon: 'medical-bag',
      }
      /*
      {
        label: 'Dropdown',
        icon: 'arrow-down-bold-circle',
        menu: [
          {
            href: '#void',
            label: 'Sub-item One',
          },
          {
            href: '#void',
            label: 'Sub-item Two',
          },
        ],
      }
      */
    )

    menu.push(adminMenu)
  }

  return menu
}

export function getMenuBottom(): Array<any> {
  return [
    {
      action: 'logout',
      icon: 'logout',
      label: 'Disconnetti',
      state: 'info',
    },
  ]
}
