import store from '@/store'
import { Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import { IExam, IExamCreate, IExamUpdate } from '../interfaces/exam-model'
import { examApi } from '../api/admin/exams'
import { BaseModuleWithSearch, IModuleMessages } from './base'
import { UserStore } from './user'
import { checkApiError } from '@/store/api/error-handlers'

export interface IExamState {
  data: IExam[]
  filteredData: IExam[]
  current: IExam | null
  notReportedExams: IExam[]
}

@Module({ dynamic: true, store, name: 'exam', namespaced: true })
class Exam extends BaseModuleWithSearch<IExam, IExamCreate, IExamUpdate> {
  messages: IModuleMessages = {
    create: 'Esame inserito con successo.',
    update: 'Esame modificato con successo.',
    delete: 'Esame rimosso correttamente.',
  }
  api = examApi

  notReportedExams: IExam[] = []

  @Mutation
  RESET_STATE() {
    super.RESET_STATE()
    this.notReportedExams = []
  }

  @Mutation
  protected SET_NOT_REPORTED_EXAMS(payload: IExam[]) {
    this.notReportedExams = payload
  }

  @Mutation
  protected APPEND_NOT_REPORTED_EXAMS(payload: IExam[]) {
    this.notReportedExams = this.notReportedExams.concat(payload)
  }

  @Action
  async actionGetMultiByPatientId(payload: {
    patientId: number
    skip?: number
    limit?: number
  }): Promise<number | null> {
    try {
      const response = await this.api.getMultiByPatientId(
        UserStore.token,
        payload.patientId,
        payload.skip,
        payload.limit
      )
      if (response) {
        if (payload.skip && payload.skip > 0) {
          this.context.commit('APPEND_DATA', response.data)
        } else {
          this.context.commit('SET_DATA', response.data)
        }
      }
      return response.data.length
    } catch (error) {
      checkApiError(error)
      return null
    }
  }

  @Action
  async actionGetMultiNotReported(payload: { skip?: number; limit?: number }): Promise<number | null> {
    try {
      const response = await this.api.getMultiNotReported(UserStore.token, payload.skip, payload.limit)
      if (response) {
        if (payload.skip && payload.skip > 0) {
          this.context.commit('APPEND_NOT_REPORTED_EXAMS', response.data)
        } else {
          this.context.commit('SET_NOT_REPORTED_EXAMS', response.data)
        }
      }
      return response.data.length
    } catch (error) {
      checkApiError(error)
      return null
    }
  }
}

export const ExamStore = getModule(Exam)
