









import { Component, Vue } from 'vue-property-decorator'
import LoadingFullPage from '@/components/Eterian/LoadingFullPage.vue'
import { UserStore } from '@/store/modules/user'

@Component({
  components: {
    LoadingFullPage,
  },
})
export default class App extends Vue {
  // Computed Data
  get loggedIn() {
    return UserStore.isLoggedIn
  }
}
