












import { Vue, Component, Prop } from 'vue-property-decorator'
import PDFObject from 'pdfobject'

@Component
export default class PDFViewer extends Vue {
  // Properties
  @Prop({ type: String })
  pdfSrc!: string
  @Prop({ type: String })
  fileName!: string

  // Vue Hooks
  mounted() {
    const options = {
      page: '1',
    }
    PDFObject.embed(this.pdfSrc, '#pdf-embed', options)
  }
}
