import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IPayment } from '@/store/interfaces/payment-model'

export const paymentApi = {
  async getAll(token: string) {
    return axios.get<IPayment[]>(`${apiUrl}/v1/admin/payments/`, authConfig(token))
  },
}
