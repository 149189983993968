























































































import { Vue, Component } from 'vue-property-decorator'
import NavBarMenu from '@/components/Eterian/NavBarMenu.vue'
import UserAvatar from '@/components/Eterian/UserAvatar.vue'
import { AppStore } from '@/store/modules/app'
import { UserStore } from '@/store/modules/user'
import { showInfoNotification } from '@/utils/notification'
import { PatientStore } from '@/store/modules/patient'
import { RoutePaths } from '@/router/route-details'

@Component({
  components: {
    NavBarMenu,
    UserAvatar,
  },
})
export default class NavBar extends Vue {
  // Data
  isMenuNavBarActive = false
  patientFilterString = ''

  // Computed Data
  get menuNavBarToggleIcon() {
    return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
  }
  get menuToggleMobileIcon() {
    return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
  }
  get menuToggleIcon() {
    return this.isAsideExpanded ? 'backburger' : 'forwardburger'
  }
  get toggleTooltip() {
    return this.isAsideExpanded ? 'Collapse' : 'Expand'
  }
  get isNavBarVisible() {
    return AppStore.isNavBarVisible
  }
  get isAsideExpanded() {
    return AppStore.isAsideExpanded
  }
  get isAsideMobileExpanded() {
    return AppStore.isAsideMobileExpanded
  }
  get isAsideRightVisible() {
    return AppStore.isAsideRightVisible
  }
  get boundCompanyName() {
    if (UserStore.boundCompany) {
      return UserStore.boundCompany.business_name
        ? UserStore.boundCompany.business_name
        : UserStore.boundCompany.name + ' ' + UserStore.boundCompany.surname
    }
    return null
  }
  get userName() {
    if (UserStore.userProfile) {
      if (this.boundCompanyName) {
        return `${UserStore.userProfile.full_name} (${this.boundCompanyName})`
      }
      return UserStore.userProfile.full_name
    }
    return null
  }
  get isAdministrator() {
    return UserStore.hasAdminAccess && UserStore.userProfile?.company_id
  }
  get hasUpdates() {
    return AppStore.hasUpdates
  }

  // Methods
  openAboutModal() {
    AppStore.SET_IS_ABOUT_ACTIVE(true)
  }
  menuToggle() {
    AppStore.asideStateToggle()
  }
  menuToggleMobile() {
    AppStore.asideMobileStateToggle()
  }
  menuNavBarToggle() {
    this.isMenuNavBarActive = !this.isMenuNavBarActive
  }
  updatesToggle() {
    AppStore.asideRightToggle()
  }
  logout() {
    UserStore.actionUserLogOut()
  }

  async searchPatient() {
    const result = await PatientStore.actionSearch(this.patientFilterString)
    if (result) {
      AppStore.SET_PATIENT_FILTER_STRING(this.patientFilterString)
      if (this.$route.path != RoutePaths.patients()) this.$router.push(RoutePaths.patients())
    }
    this.patientFilterString = ''
    document.getElementById('navSearchField')?.blur()
  }

  async unbindCompany() {
    const result = await UserStore.actionBindToCompany(-1)
    if (result) {
      showInfoNotification({
        content: 'Sei uscito dalla modalità "Assistenza Aziendale"',
        duration: 4000,
      })
    }
  }
}
