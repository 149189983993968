/* Core */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* UI and Styles */
import Buefy from 'buefy'
import '@/style/scss/main.scss'
import '@mdi/font/css/materialdesignicons.min.css'

/* Commons */
import './utils/vue-filters'
import './utils/validation-rules'

/* Setup dayjs */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/it'
dayjs.extend(customParseFormat)
dayjs.locale('it')

Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultFirstDayOfWeek: 1,
  defaultDayNames: ['D', 'L', 'Ma', 'Me', 'G', 'V', 'S'],
  defaultMonthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  defaultDateFormatter: (date: Date) => dayjs(date).format('DD/MM/YYYY'),
  defaultDateParser: (date: string) => dayjs(date, ['DD/MM/YYYY', 'DDMMYYYY', 'DD-MM-YYYY']).toDate(),
  defaultToastDuration: 4000,
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
