














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavBarMenu extends Vue {
  // Properties
  @Prop({ type: Boolean, default: false })
  isHoverable!: boolean

  // Data
  isDropdownActive = false

  // Computed Data
  get toggleDropdownIcon() {
    return this.isDropdownActive ? 'chevron-up' : 'chevron-down'
  }

  // Vue Hooks
  mounted() {
    window.addEventListener('click', this.forceClose)
  }
  beforeDestroy() {
    window.removeEventListener('click', this.forceClose)
  }

  // Methods
  toggle() {
    if (!this.isHoverable) {
      this.isDropdownActive = !this.isDropdownActive
    }
  }
  forceClose(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isDropdownActive = false
    }
  }
}
