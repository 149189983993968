import { ToastProgrammatic as Toast, SnackbarProgrammatic as Snackbar } from 'buefy'

export interface IAppNotification {
  content: string
  position?: 'is-top-right' | 'is-top' | 'is-top-left' | 'is-bottom-right' | 'is-bottom' | 'is-bottom-left' | undefined
  duration?: number | undefined
  actionText?: string
  indefinite?: boolean
}

type NotificationType =
  | 'is-dark'
  | 'is-white'
  | 'is-black'
  | 'is-light'
  | 'is-dark'
  | 'is-primary'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | undefined

function showToast(notification: IAppNotification, type?: NotificationType) {
  Toast.open({
    type: type,
    message: notification.content,
    position: notification.position ? notification.position : 'is-top',
    duration: notification.duration,
    queue: false,
  })
}

function showSnackbar(notification: IAppNotification, type?: NotificationType) {
  Snackbar.open({
    type: type,
    message: notification.content,
    position: notification.position ? notification.position : 'is-top',
    actionText: notification.actionText,
    indefinite: notification.indefinite,
  })
}

export const showNotification = (notification: IAppNotification) => showToast(notification)
export const showSuccessNotification = (notification: IAppNotification) => showToast(notification, 'is-success')
export const showInfoNotification = (notification: IAppNotification) => showToast(notification, 'is-info')
export const showErrorNotification = (notification: IAppNotification) => showToast(notification, 'is-danger')
export const showSnackbarNotification = (notification: IAppNotification, type?: NotificationType) =>
  showSnackbar(notification, type)
