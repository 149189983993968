





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { toTitleCase, capitalizeFirstLetter } from '@/utils/string'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class InputFieldWithValidation extends Vue {
  $refs!: {
    input: HTMLFormElement
  }

  // Properties
  @Prop({ type: String, required: true })
  name!: string
  @Prop({ type: String })
  label!: string
  @Prop({ type: [String, Number] })
  value!: string | number
  @Prop({ type: String })
  placeholder!: string
  @Prop({ type: String })
  size!: 'is-small' | 'is-medium' | 'is-large'
  @Prop({ type: String, default: 'text' })
  type!: string
  @Prop({ type: Boolean, default: false })
  revealOff!: boolean
  @Prop({ type: Boolean, default: true })
  horizontal!: boolean
  @Prop({ type: Boolean, default: false })
  autofocus!: boolean
  @Prop({ type: Boolean, default: false })
  upperCase!: boolean
  @Prop({ type: Boolean, default: false })
  titleCase!: boolean
  @Prop({ type: Boolean, default: false })
  firstLetterCase!: boolean
  @Prop(String)
  message: string | undefined
  @Prop(Array)
  autocompleteData: Array<string> | undefined
  @Prop([Object, String])
  rules: Record<string, any> | string | undefined
  @Prop(String)
  valMode: string | undefined
  @Prop({ type: Boolean, default: false })
  enableAutofill!: boolean

  // Data
  selected = null
  keepFirst = false

  // Computed Data
  get pLabel() {
    if (
      this.label &&
      ((typeof this.rules === 'string' && this.rules.includes('required')) ||
        (typeof this.rules === 'object' && this.rules['required']))
    ) {
      return this.label + ' *'
    }
    return this.label
  }
  get passwordReveal() {
    if (this.type == 'password') return !this.revealOff
    return undefined
  }
  get boundValue() {
    return this.value
  }
  set boundValue(value) {
    if (typeof value === 'string') {
      if (this.titleCase) {
        value = toTitleCase(value)
      } else if (this.upperCase && value) {
        value = value.toUpperCase()
      } else if (this.firstLetterCase) {
        value = capitalizeFirstLetter(value)
      }
    }
    this.$emit('input', value)
  }
  get filteredDataArray() {
    if (!this.autocompleteData || !this.boundValue) {
      return new Array(0)
    }
    return this.autocompleteData.filter((option) => {
      if (typeof this.boundValue === 'number')
        return option.toString().toLowerCase().indexOf(this.boundValue.toString()) >= 0
      else return option.toString().toLowerCase().indexOf(this.boundValue.toLowerCase()) >= 0
    })
  }

  // Vue Hooks
  mounted() {
    this.fireFocus()
  }

  // Method
  fireFocus(force = false) {
    if (this.autofocus || force) this.$refs.input.focus()
  }
  trimBoundValue() {
    if (typeof this.boundValue === 'number') return
    if (this.boundValue) this.boundValue = this.boundValue.trim()
  }
}
