import router from '@/router'
import { Dictionary } from 'vue-router/types/router'

function resolvePath(routeName: string, routeParams?: Dictionary<string>): string {
  return router.resolve({ name: routeName, params: routeParams }).resolved.path
}

interface IRouteNames {
  login: string
  passwordRecovery: string
  resetPassword: string
  signup: string
  signupConfirmation: string

  dashboard: string
  profile: string
  billing: string
  settings: string
  updateSettings: string

  users: string
  addUser: string

  companies: string
  addCompany: string
  updateCompany: string
  updateCompanyMe: string

  operators: string
  addOperator: string
  updateOperator: string

  patients: string
  addPatient: string
  patient: string
  updatePatient: string

  examTypes: string
  exams: string
  addExam: string
  exam: string
  updateExam: string
  addReport: string
}

interface IRoutePaths {
  login: () => string
  passwordRecovery: () => string
  resetPassword: () => string
  signup: () => string
  signupConfirmation: () => string

  dashboard: () => string
  profile: () => string
  billing: () => string
  settings: () => string
  updateSettings: () => string

  users: () => string
  addUser: () => string

  companies: () => string
  addCompany: () => string
  updateCompany: (id: number) => string
  updateCompanyMe: () => string

  operators: () => string
  addOperator: () => string
  updateOperator: (id: number) => string

  patients: () => string
  addPatient: () => string
  patient: (id: number) => string
  updatePatient: (id: number) => string

  examTypes: () => string
  exams: () => string
  addExam: () => string
  exam: (id: number) => string
  updateExam: (id: number) => string
  addReport: (id: number) => string
}

export const RouteNames: Readonly<IRouteNames> = {
  login: 'login',
  passwordRecovery: 'password-recovery',
  resetPassword: 'reset-password',
  signup: 'signup',
  signupConfirmation: 'signup-confirmation',

  dashboard: 'dashboard',
  profile: 'profile',
  billing: 'billing',
  settings: 'settings',
  updateSettings: 'update-settings',

  users: 'users',
  addUser: 'add-user',

  companies: 'companies',
  addCompany: 'add-company',
  updateCompany: 'update-company',
  updateCompanyMe: 'update-company-me',

  operators: 'operators',
  addOperator: 'add-operator',
  updateOperator: 'update-operator',

  patients: 'patients',
  addPatient: 'add-patient',
  patient: 'patient',
  updatePatient: 'update-patient',

  examTypes: 'exam-types',
  exams: 'exams',
  addExam: 'add-exam',
  exam: 'exam',
  updateExam: 'update-exam',
  addReport: 'add-report',
}

export const RoutePaths: Readonly<IRoutePaths> = {
  login: () => resolvePath(RouteNames.login),
  passwordRecovery: () => resolvePath(RouteNames.passwordRecovery),
  resetPassword: () => resolvePath(RouteNames.resetPassword),
  signup: () => resolvePath(RouteNames.signup),
  signupConfirmation: () => resolvePath(RouteNames.signupConfirmation),

  dashboard: () => resolvePath(RouteNames.dashboard),
  profile: () => resolvePath(RouteNames.profile),
  billing: () => resolvePath(RouteNames.billing),
  settings: () => resolvePath(RouteNames.settings),
  updateSettings: () => resolvePath(RouteNames.updateSettings),

  users: () => resolvePath(RouteNames.users),
  addUser: () => resolvePath(RouteNames.addUser),

  companies: () => resolvePath(RouteNames.companies),
  addCompany: () => resolvePath(RouteNames.addCompany),
  updateCompany: (id: number) => resolvePath(RouteNames.updateCompany, { id: id.toString() }),
  updateCompanyMe: () => resolvePath(RouteNames.updateCompanyMe),

  operators: () => resolvePath(RouteNames.operators),
  addOperator: () => resolvePath(RouteNames.addOperator),
  updateOperator: (id: number) => resolvePath(RouteNames.updateOperator, { id: id.toString() }),

  patients: () => resolvePath(RouteNames.patients),
  addPatient: () => resolvePath(RouteNames.addPatient),
  patient: (id: number) => resolvePath(RouteNames.patient, { id: id.toString() }),
  updatePatient: (id: number) => resolvePath(RouteNames.updatePatient, { id: id.toString() }),

  examTypes: () => resolvePath(RouteNames.examTypes),
  exams: () => resolvePath(RouteNames.exams),
  addExam: () => resolvePath(RouteNames.addExam),
  exam: (id: number) => resolvePath(RouteNames.exam, { id: id.toString() }),
  updateExam: (id: number) => resolvePath(RouteNames.updateExam, { id: id.toString() }),
  addReport: (id: number) => resolvePath(RouteNames.addReport, { id: id.toString() }),
}
