import { VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { checkApiError } from '../api/error-handlers'
import { AxiosResponse } from 'axios'
import { showSuccessNotification } from '@/utils/notification'
import { UserStore } from './user'
import { AppStore } from './app'

interface IBaseApi<Type, TypeCreate, TypeUpdate> {
  get(token: string, id: number): Promise<AxiosResponse<Type>>
  getAll(token: string): Promise<AxiosResponse<Type[]>>
  create(token: string, data: TypeCreate): Promise<AxiosResponse<Type>>
  update(token: string, id: number, data: TypeUpdate): Promise<AxiosResponse<Type>>
  delete(token: string, id: number): Promise<AxiosResponse<Type>>
}

interface IBaseApiWithSearch<Type, TypeCreate, TypeUpdate> extends IBaseApi<Type, TypeCreate, TypeUpdate> {
  getMulti(token: string, skip?: number, limit?: number): Promise<AxiosResponse<Type[]>>
  search(token: string, searchString: string): Promise<AxiosResponse<Type[]>>
}

export interface IModuleMessages {
  create: string
  update: string
  delete: string
}

export abstract class BaseModule<Type, TypeCreate, TypeUpdate> extends VuexModule {
  abstract messages: IModuleMessages
  abstract api: IBaseApi<Type, TypeCreate, TypeUpdate>
  data: Type[] = []
  current: Type | null = null

  @Mutation
  RESET_STATE() {
    this.data = []
    this.current = null
  }

  @Mutation
  protected SET_DATA(payload: Type[]) {
    this.data = payload
  }

  @Mutation
  SET_CURRENT(payload: Type) {
    this.current = payload
  }

  @Action
  async actionGet(id: number): Promise<boolean> {
    try {
      const response = await this.api.get(UserStore.token, id)
      if (response) {
        this.context.commit('SET_CURRENT', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionGetAll(): Promise<boolean> {
    try {
      const response = await this.api.getAll(UserStore.token)
      if (response) {
        this.context.commit('SET_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionCreate(payload: TypeCreate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([this.api.create(UserStore.token, payload), new Promise((resolve) => setTimeout(resolve, 500))])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.create })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionUpdate(payload: { id: number; obj: TypeUpdate }): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await Promise.all([
        this.api.update(UserStore.token, payload.id, payload.obj),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      if (response[0]) this.context.commit('SET_CURRENT', response[0].data)
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.update })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDelete(id: number): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([this.api.delete(UserStore.token, id), new Promise((resolve) => setTimeout(resolve, 500))])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.delete })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }
}

export abstract class BaseModuleWithSearch<Type, TypeCreate, TypeUpdate> extends BaseModule<
  Type,
  TypeCreate,
  TypeUpdate
> {
  abstract api: IBaseApiWithSearch<Type, TypeCreate, TypeUpdate>
  filteredData: Type[] = []

  @Mutation
  RESET_STATE() {
    this.data = []
    this.filteredData = []
  }

  @Mutation
  protected APPEND_DATA(payload: Type[]) {
    this.data = this.data.concat(payload)
  }

  @Mutation
  protected SET_FILTERED_DATA(payload: Type[]) {
    this.filteredData = payload
  }

  @Action
  async actionGetMulti(payload: { skip?: number; limit?: number } = {}): Promise<number | null> {
    try {
      const response = await this.api.getMulti(UserStore.token, payload.skip, payload.limit)
      if (response) {
        if (payload.skip && payload.skip > 0) {
          this.context.commit('APPEND_DATA', response.data)
        } else {
          this.context.commit('SET_DATA', response.data)
        }
      }
      return response.data.length
    } catch (error) {
      checkApiError(error)
      return null
    }
  }

  @Action
  async actionSearch(searchString: string): Promise<boolean> {
    try {
      const response = await this.api.search(UserStore.token, searchString)
      if (response) {
        this.context.commit('SET_FILTERED_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }
}
