
















import { Vue, Component } from 'vue-property-decorator'
import AboutModal from '@/components/Eterian/AboutModal.vue'
import LoadingFullPage from '@/components/Eterian/LoadingFullPage.vue'
import NavBar from '@/components/Eterian/NavBar.vue'
import AsideMenu from '@/components/Eterian/AsideMenu.vue'
import FooterBar from '@/components/Eterian/FooterBar.vue'
import Overlay from '@/components/Eterian/Overlay.vue'
import { AppStore } from '@/store/modules/app'
import { UserStore } from '@/store/modules/user'
import { getMenu, getMenuBottom } from '@/router/menu'

@Component({
  components: {
    AboutModal,
    LoadingFullPage,
    NavBar,
    AsideMenu,
    FooterBar,
    Overlay,
  },
})
export default class Main extends Vue {
  // Computed Data
  get isLoading(): boolean {
    return AppStore.isLoading
  }
  get menu() {
    return getMenu()
  }
  get menuBottom() {
    return getMenuBottom()
  }

  // Vue Hooks
  mounted() {
    AppStore.actionCheckAppSetting()
  }

  // Methods
  menuClick(item: any) {
    if (item.action && item.action === 'logout') {
      UserStore.actionUserLogOut()
    }
  }
}
