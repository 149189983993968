import store from '@/store'
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators'
import { getBooleanSetting, saveBooleanSetting } from '@/utils/local-storage'

export interface IAppState {
  isLoading: boolean
  isNavBarVisible: boolean
  isFooterBarVisible: boolean
  isAsideVisible: boolean
  isAsideExpanded: boolean
  isAsideMobileExpanded: boolean
  isAsideRightVisible: boolean
  hasUpdates: boolean
  isOverlayVisible: boolean
  isAboutActive: boolean
  isContainerFluid: boolean
}

@Module({ dynamic: true, store, name: 'app', namespaced: true })
class App extends VuexModule implements IAppState {
  patientFilterString = ''
  isLoading = false
  isNavBarVisible = true
  isFooterBarVisible = true
  isAsideVisible = true
  isAsideExpanded = false
  isAsideMobileExpanded = false
  isAsideRightVisible = false
  hasUpdates = false
  isOverlayVisible = false
  isAboutActive = false
  isContainerFluid = false

  @Mutation
  RESET_STATE() {
    this.patientFilterString = ''
    this.isLoading = false
    this.isNavBarVisible = true
    this.isFooterBarVisible = true
    this.isAsideVisible = true
    this.isAsideExpanded = false
    this.isAsideMobileExpanded = false
    this.isAsideRightVisible = false
    this.hasUpdates = false
    this.isOverlayVisible = false
    this.isAboutActive = false
    this.isContainerFluid = false
  }

  @Mutation
  SET_PATIENT_FILTER_STRING(payload: string) {
    this.patientFilterString = payload
  }

  @Mutation
  SET_IS_LOADING(payload: boolean) {
    this.isLoading = payload
  }

  @Mutation
  SET_IS_NAV_BAR_VISIBLE(payload: boolean) {
    this.isNavBarVisible = payload
  }

  @Mutation
  SET_IS_FOOTER_BAR_VISIBLE(payload: boolean) {
    this.isFooterBarVisible = payload
  }

  @Mutation
  SET_IS_ASIDE_VISIBLE(payload: boolean) {
    this.isAsideVisible = payload
  }

  @Mutation
  SET_IS_ASIDE_EXPANDED(payload: boolean) {
    this.isAsideExpanded = payload
  }

  @Mutation
  SET_IS_ASIDE_MOBILE_EXPANDED(payload: boolean) {
    this.isAsideMobileExpanded = payload
  }

  @Mutation
  SET_IS_ASIDE_RIGHT_VISIBLE(payload: boolean) {
    this.isAsideRightVisible = payload
  }

  @Mutation
  SET_HAS_UPDATES(payload: boolean) {
    this.hasUpdates = payload
  }

  @Mutation
  SET_IS_OVERLAY_VISIBLE(payload: boolean) {
    this.isOverlayVisible = payload
  }

  @Mutation
  SET_IS_ABOUT_ACTIVE(payload: boolean) {
    this.isAboutActive = payload
  }

  @Mutation
  SET_IS_CONTAINER_FLUID(payload: boolean) {
    this.isContainerFluid = payload
  }

  /* Aside Desktop */
  @Mutation
  asideStateToggle(payload: boolean | null = null) {
    const htmlAsideClassName = 'has-aside-expanded'

    let isExpand

    if (payload !== null) {
      isExpand = payload
    } else {
      isExpand = !this.isAsideExpanded
    }

    if (isExpand) {
      document.documentElement.classList.add(htmlAsideClassName)
    } else {
      document.documentElement.classList.remove(htmlAsideClassName)
    }

    this.isAsideExpanded = isExpand
    saveBooleanSetting('aside_setting', this.isAsideExpanded)
  }

  /* Aside Mobile */
  @Mutation
  asideMobileStateToggle(payload: boolean | null = null) {
    const htmlClassName = 'has-aside-mobile-expanded'

    let isShow

    if (payload !== null) {
      isShow = payload
    } else {
      isShow = !this.isAsideMobileExpanded
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }

    this.isAsideMobileExpanded = isShow
  }

  /* Aside Right */
  @Mutation
  asideRightToggle(payload: boolean | null = null) {
    const htmlClassName = 'has-aside-right'

    let isShow

    if (payload !== null) {
      isShow = payload
    } else {
      isShow = !this.isAsideRightVisible
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }

    this.isAsideRightVisible = isShow
    this.hasUpdates = false
  }

  @Mutation
  overlayToggle(payload: boolean | null = null) {
    if (payload === null) {
      payload = !this.isOverlayVisible
    }

    this.isOverlayVisible = !!payload
  }

  @Mutation
  containerFluidToggle(payload: boolean | null = null) {
    if (payload === null) {
      payload = !this.isContainerFluid
    }

    this.isContainerFluid = !!payload
    saveBooleanSetting('container_fluid_setting', this.isContainerFluid)
  }

  @Action
  actionCheckAppSetting() {
    const asideSetting = getBooleanSetting('aside_setting')
    if (this.isAsideExpanded != asideSetting) {
      this.asideStateToggle(asideSetting)
    }

    const containerFluidSetting = getBooleanSetting('container_fluid_setting')
    if (this.isContainerFluid != containerFluidSetting) {
      this.context.commit('SET_IS_CONTAINER_FLUID', containerFluidSetting)
    }
  }
}

export const AppStore = getModule(App)
