





















import { Vue, Component } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { AppStore } from '@/store/modules/app'

@Component
export default class FooterBar extends Vue {
  // Computed Data
  get year() {
    return dayjs().year()
  }
  get isFooterBarVisible() {
    return AppStore.isFooterBarVisible
  }
}
