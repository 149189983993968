import store from '@/store'
import { Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import { IExamType, IExamTypeCreate, IExamTypeUpdate } from '../interfaces/exam-type-model'
import { examTypeApi } from '../api/admin/exam-types'
import { BaseModule, IModuleMessages } from './base'

export interface IExamTypeState {
  data: IExamType[]
  filteredData: IExamType[]
}

@Module({ dynamic: true, store, name: 'examType', namespaced: true })
class ExamType extends BaseModule<IExamType, IExamTypeCreate, IExamTypeUpdate> {
  messages: IModuleMessages = {
    create: "Tipologia d'Esame inserita con successo.",
    update: "Tipologia d'Esame modificata con successo.",
    delete: "Tipologia d'Esame rimossa correttamente.",
  }
  api = examTypeApi

  filteredData: IExamType[] = []

  @Mutation
  RESET_STATE() {
    super.RESET_STATE()
    this.filteredData = []
  }

  @Mutation
  SET_FILTERED_DATA(payload: IExamType[]) {
    this.filteredData = payload
  }

  @Action
  actionStaticSearch(searchString: string) {
    const searchFinal = searchString.toLowerCase()
    const filtered = this.data.filter((examType) => examType.name.toLowerCase().includes(searchFinal))
    this.context.commit('SET_FILTERED_DATA', filtered)
  }
}

export const ExamTypeStore = getModule(ExamType)
