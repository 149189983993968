import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from '@/store/interfaces/user-model'

export const operatorApi = {
  async getAll(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/v1/admin/operators/`, authConfig(token))
  },
  async create(token: string, data: IUserProfileCreate) {
    return axios.post<IUserProfile>(`${apiUrl}/v1/admin/operators/`, data, authConfig(token))
  },
  async get(token: string, operatorId: number) {
    return axios.get<IUserProfile>(`${apiUrl}/v1/admin/operators/${operatorId}`, authConfig(token))
  },
  async update(token: string, operatorId: number, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/v1/admin/operators/${operatorId}`, data, authConfig(token))
  },
  async delete(token: string, operatorId: number) {
    return axios.delete<IUserProfile>(`${apiUrl}/v1/admin/operators/${operatorId}`, authConfig(token))
  },
}
