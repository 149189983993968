




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LoadingFullPage extends Vue {
  @Prop({ type: Boolean, required: true })
  isLoading!: boolean
}
