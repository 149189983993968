import Vue from 'vue'
import Vuex from 'vuex'

import { IAppState } from './modules/app'
import { ICompanyState } from './modules/company'
import { IExamState } from './modules/exam'
import { IExamTypeState } from './modules/exam-type'
import { IOperatorState } from './modules/operator'
import { IPatientState } from './modules/patient'
import { IPaymentState } from './modules/payment'
import { IReportState } from './modules/report'
import { ISettingsState } from './modules/settings'
import { IStatsState } from './modules/stats'
import { ISuperUserState } from './modules/superuser'
import { IUserState } from './modules/user'

Vue.use(Vuex)

export interface IRootState {
  app: IAppState
  company: ICompanyState
  exam: IExamState
  examType: IExamTypeState
  operator: IOperatorState
  patient: IPatientState
  payment: IPaymentState
  report: IReportState
  settings: ISettingsState
  stats: IStatsState
  superuser: ISuperUserState
  user: IUserState
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
