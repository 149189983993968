import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { IDashboard } from '../interfaces/stat-model'
import { statsApi } from '../api/admin/stats'
import { UserStore } from './user'
import { checkApiError } from '../api/error-handlers'

export interface IStatsState {
  dashboard: IDashboard
}

@Module({ dynamic: true, store, name: 'stats', namespaced: true })
class Stats extends VuexModule {
  dashboard: IDashboard | null = null

  @Mutation
  RESET_STATE() {
    this.dashboard = null
  }

  @Mutation
  private SET_DASHBOARD(payload: IDashboard) {
    this.dashboard = payload
  }

  @Action
  async actionGetDashboard(): Promise<boolean> {
    try {
      const response = await statsApi.getDashboard(UserStore.token)
      if (response) {
        this.context.commit('SET_DASHBOARD', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }
}

export const StatsStore = getModule(Stats)
