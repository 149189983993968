




























import { Vue, Component } from 'vue-property-decorator'
import { AppStore } from '@/store/modules/app'

@Component
export default class AboutModal extends Vue {
  // Computed Data
  get isAboutActive() {
    return AppStore.isAboutActive
  }
  set isAboutActive(value) {
    AppStore.SET_IS_ABOUT_ACTIVE(value)
  }
}
