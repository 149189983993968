import store from '@/store'
import { Module, getModule, Action } from 'vuex-module-decorators'
import { IUserProfile, IUserProfileCreate, IUserProfileUpdate } from '../interfaces/user-model'
import { userApi } from '../api/super/users'
import { BaseModule, IModuleMessages } from './base'
import { AppStore } from './app'
import { UserStore } from './user'
import { showSuccessNotification } from '@/utils/notification'
import { checkApiError } from '../api/error-handlers'

export interface ISuperUserState {
  superUsers: IUserProfile[]
}

@Module({ dynamic: true, store, name: 'superuser', namespaced: true })
class SuperUser extends BaseModule<IUserProfile, IUserProfileCreate, IUserProfileUpdate> {
  messages: IModuleMessages = {
    create: 'Superuser inserito con successo.',
    update: 'Superuser modificato con successo.',
    delete: 'Superuser rimosso correttamente.',
  }
  api = userApi

  @Action
  async actionGetAll(): Promise<boolean> {
    try {
      const response = await this.api.getSupers(UserStore.token)
      if (response) {
        this.context.commit('SET_DATA', response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionCreate(payload: IUserProfileCreate): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        userApi.createSuper(UserStore.token, payload),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.create })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionUpdate(payload: { id: number; obj: IUserProfileUpdate }): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        this.api.updateSuper(UserStore.token, payload.id, payload.obj),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.update })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDelete(id: number): Promise<boolean> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        userApi.deleteSuper(UserStore.token, id),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: this.messages.delete })
      return true
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      checkApiError(error)
      return false
    }
  }
}

export const SuperUserStore = getModule(SuperUser)
