import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig, blobConfig } from '@/store/api/configs'
import { IReport, IReportCreate, IReportUpdate } from '@/store/interfaces/report-model'

export const reportApi = {
  async getAll(token: string) {
    return axios.get<IReport[]>(`${apiUrl}/v1/admin/reports/`, authConfig(token))
  },
  async create(token: string, data: IReportCreate) {
    return axios.post<IReport>(`${apiUrl}/v1/admin/reports/`, data, authConfig(token))
  },
  async get(token: string, reportId: number) {
    return axios.get<IReport>(`${apiUrl}/v1/admin/reports/${reportId}`, authConfig(token))
  },
  async update(token: string, reportId: number, data: IReportUpdate) {
    return axios.put<IReport>(`${apiUrl}/v1/admin/reports/${reportId}`, data, authConfig(token))
  },
  async delete(token: string, reportId: number) {
    return axios.delete<IReport>(`${apiUrl}/v1/admin/reports/${reportId}`, authConfig(token))
  },
  async getAllByExamId(token: string, examId: number) {
    return axios.get<IReport[]>(`${apiUrl}/v1/admin/reports/exam/${examId}`, authConfig(token))
  },
  async download(token: string, reportId: number) {
    return axios.get(`${apiUrl}/v1/admin/reports/${reportId}/download`, blobConfig(token))
  },
}
