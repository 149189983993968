export const getLocalToken = () => localStorage.getItem('token')

export const saveLocalToken = (token: string) => localStorage.setItem('token', token)

export const removeLocalToken = () => localStorage.removeItem('token')

export const getBooleanSetting = (name: string) => localStorage.getItem(name) == 'true'

export const saveBooleanSetting = (name: string, value: boolean) => localStorage.setItem(name, value.toString())

export const removeBooleanSetting = (name: string) => localStorage.removeItem(name)
