import axios from 'axios'
import { apiUrl } from '@/env'
import { ICompany, IWebCompanyCreate } from '../interfaces/company-model'
import { showSuccessNotification } from '@/utils/notification'
import { checkApiError } from './error-handlers'

const webApi = {
  async registerCompany(data: IWebCompanyCreate) {
    return axios.post<ICompany>(`${apiUrl}/v1/web/signup`, data)
  },
  async confirmEmail(token: string) {
    return axios.post(`${apiUrl}/v1/web/confirm-email`, token)
  },
}

export async function actionRegisterCompany(payload: IWebCompanyCreate) {
  try {
    await Promise.all([webApi.registerCompany(payload), new Promise((resolve) => setTimeout(resolve, 500))])
    showSuccessNotification({ content: 'Registrazione completata con successo' })
    return true
  } catch (error) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    checkApiError(error)
    return false
  }
}

export async function actionPerformEmailVerification(token: string) {
  try {
    await webApi.confirmEmail(token)
    showSuccessNotification({ content: 'Email verificata con successo.' })
  } catch (error) {
    checkApiError(error)
  }
}
