import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IExamType, IExamTypeCreate, IExamTypeUpdate } from '@/store/interfaces/exam-type-model'

export const examTypeApi = {
  async getAll(token: string) {
    return axios.get<IExamType[]>(`${apiUrl}/v1/admin/exam-types/`, authConfig(token))
  },
  async search(token: string, searchString: string) {
    return axios.get<IExamType[]>(`${apiUrl}/v1/admin/exam-types/search/${searchString}`, authConfig(token))
  },
  async create(token: string, data: IExamTypeCreate) {
    return axios.post<IExamType>(`${apiUrl}/v1/admin/exam-types/`, data, authConfig(token))
  },
  async get(token: string, examTypeId: number) {
    return axios.get<IExamType>(`${apiUrl}/v1/admin/exam-types/${examTypeId}`, authConfig(token))
  },
  async update(token: string, examTypeId: number, data: IExamTypeUpdate) {
    return axios.put<IExamType>(`${apiUrl}/v1/admin/exam-types/${examTypeId}`, data, authConfig(token))
  },
  async delete(token: string, examTypeId: number) {
    return axios.delete<IExamType>(`${apiUrl}/v1/admin/exam-types/${examTypeId}`, authConfig(token))
  },
}
