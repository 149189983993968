import store from '@/store'
import { Module, getModule } from 'vuex-module-decorators'
import { ICompany, ICompanyCreate, ISudoCompanyUpdate } from '../interfaces/company-model'
import { companyApi } from '../api/super/companies'
import { BaseModuleWithSearch, IModuleMessages } from './base'

export interface ICompanyState {
  companies: ICompany[]
  filteredCompanies: ICompany[]
}

@Module({ dynamic: true, store, name: 'company', namespaced: true })
class Company extends BaseModuleWithSearch<ICompany, ICompanyCreate, ISudoCompanyUpdate> {
  messages: IModuleMessages = {
    create: 'Azienda inserita con successo.',
    update: 'Azienda modificata con successo.',
    delete: 'Azienda rimossa correttamente.',
  }
  api = companyApi
}

export const CompanyStore = getModule(Company)
