












































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { UserStore } from '@/store/modules/user'
import { AppStore } from '@/store/modules/app'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
  },
})
export default class PasswordRecovery extends Vue {
  // Data
  email = ''

  // Computed Data
  get isLoading() {
    return AppStore.isLoading
  }

  // Methods
  onCancel() {
    this.$router.back()
  }
  onSubmit() {
    if (this.email) UserStore.actionPasswordRecovery(this.email)
  }
}
