import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IExam, IExamCreate, IExamUpdate } from '@/store/interfaces/exam-model'
import { NotImplementedError } from '../error-handlers'
import { IPatient } from '@/store/interfaces/patient-model'

export const examApi = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAll(token: string) {
    throw new NotImplementedError()
  },
  async getMulti(token: string, skip = 0, limit = 100) {
    return axios.get<IExam[]>(`${apiUrl}/v1/admin/exams/?skip=${skip}&limit=${limit}`, authConfig(token))
  },
  async search(token: string, searchString: string) {
    return axios.get<IExam[]>(`${apiUrl}/v1/admin/exams/search/${searchString}`, authConfig(token))
  },
  async create(token: string, data: IExamCreate) {
    return axios.post<IExam>(`${apiUrl}/v1/admin/exams/`, data, authConfig(token))
  },
  async get(token: string, examId: number) {
    return axios.get<IExam>(`${apiUrl}/v1/admin/exams/${examId}`, authConfig(token))
  },
  async update(token: string, examId: number, data: IExamUpdate) {
    return axios.put<IExam>(`${apiUrl}/v1/admin/exams/${examId}`, data, authConfig(token))
  },
  async delete(token: string, examId: number) {
    return axios.delete<IExam>(`${apiUrl}/v1/admin/exams/${examId}`, authConfig(token))
  },
  async getMultiByPatientId(token: string, patientId: number, skip = 0, limit = 100) {
    return axios.get<IPatient[]>(
      `${apiUrl}/v1/admin/exams/patient/${patientId}?skip=${skip}&limit=${limit}`,
      authConfig(token)
    )
  },
  async getMultiNotReported(token: string, skip = 0, limit = 100) {
    return axios.get<IExam[]>(`${apiUrl}/v1/admin/exams/not-reported?skip=${skip}&limit=${limit}`, authConfig(token))
  },
}
