import store from '@/store'
import { Module, getModule } from 'vuex-module-decorators'
import { IUserProfile, IUserProfileCreate, IUserProfileUpdate } from '../interfaces/user-model'
import { operatorApi } from '../api/admin/operators'
import { BaseModule, IModuleMessages } from './base'

export interface IOperatorState {
  operators: IUserProfile[]
}

@Module({ dynamic: true, store, name: 'operator', namespaced: true })
class Operator extends BaseModule<IUserProfile, IUserProfileCreate, IUserProfileUpdate> {
  messages: IModuleMessages = {
    create: 'Operatore inserito con successo.',
    update: 'Operatore modificato con successo.',
    delete: 'Operatore rimosso correttamente.',
  }
  api = operatorApi
}

export const OperatorStore = getModule(Operator)
