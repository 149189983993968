import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import {
  IProductPlan,
  IBillingPublicKey,
  IBillingCheckoutSession,
  IBillingCreateCheckoutSession,
  IBillingCustomerPortalSession,
} from '@/store/interfaces/billing-model'
import { UserStore } from '@/store/modules/user'
import { AppStore } from '@/store/modules/app'
import { checkApiError } from '../error-handlers'

export const billingApi = {
  async getProductPlans(token: string) {
    return axios.get<IProductPlan[]>(`${apiUrl}/v1/admin/billing/plans`, authConfig(token))
  },
  async getPublicKey(token: string) {
    return axios.get<IBillingPublicKey>(`${apiUrl}/v1/admin/billing/public-key`, authConfig(token))
  },
  async createCheckoutSession(token: string, data: IBillingCreateCheckoutSession) {
    return axios.post<IBillingCheckoutSession>(
      `${apiUrl}/v1/admin/billing/create-checkout-session`,
      data,
      authConfig(token)
    )
  },
  async createCustomerPortalSession(token: string) {
    return axios.get<IBillingCustomerPortalSession>(
      `${apiUrl}/v1/admin/billing/create-customer-portal-session`,
      authConfig(token)
    )
  },
}

declare let Stripe: any

async function getPublicKey(): Promise<string> {
  const response = await billingApi.getPublicKey(UserStore.token)
  return response.data.public_key
}

export function loadStripe() {
  if (document.getElementById('script-stripe')) return
  const stripeScriptTag = document.createElement('script')
  stripeScriptTag.setAttribute('src', '//js.stripe.com/v3/')
  stripeScriptTag.setAttribute('id', 'script-stripe')
  document.head.appendChild(stripeScriptTag)
}

export async function openCheckoutSession(price_id: string) {
  try {
    AppStore.SET_IS_LOADING(true)
    const stripePublicKey = await getPublicKey()
    const stripe = Stripe(stripePublicKey)
    const reqData: IBillingCreateCheckoutSession = {
      price_id: price_id,
    }
    const response = await Promise.all([
      billingApi.createCheckoutSession(UserStore.token, reqData),
      new Promise((resolve) => setTimeout(resolve, 500)),
    ])
    stripe.redirectToCheckout({ sessionId: response[0].data.session_id })
  } catch (error) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    AppStore.SET_IS_LOADING(false)
    checkApiError(error)
  }
}

export async function openCustomerPortalSession() {
  try {
    AppStore.SET_IS_LOADING(true)
    const response = await Promise.all([
      billingApi.createCustomerPortalSession(UserStore.token),
      new Promise((resolve) => setTimeout(resolve, 500)),
    ])
    window.location.href = response[0].data.session_url
  } catch (error) {
    AppStore.SET_IS_LOADING(false)
    checkApiError(error)
  }
}
