import { AxiosResponse } from 'axios'
import { ModalProgrammatic as Modal } from 'buefy'
import PDFObject from 'pdfobject'
import PDFViewer from '@/components/Eterian/PDFViewer.vue'

function getFileNameFromHeaders(response: AxiosResponse<any>, defaultFileName: string) {
  if (!response) return
  const contentDisposition = response.headers['content-disposition']
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (fileNameMatch.length === 2) return fileNameMatch[1]
  }
  return defaultFileName
}

export function saveBlobResponseAsFile(response: AxiosResponse<any>, defaultFileName: string) {
  if (!response) return
  const blob = new Blob([response.data])
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = getFileNameFromHeaders(response, defaultFileName)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function openPdfResponseOnModal(response: AxiosResponse<any>, defaultFileName: string) {
  if (!response) return
  if (!PDFObject.supportsPDFs) {
    saveBlobResponseAsFile(response, defaultFileName)
    return
  }
  const fileName = getFileNameFromHeaders(response, defaultFileName)
  const blob = new Blob([response.data], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(blob)
  Modal.open({
    component: PDFViewer,
    props: { pdfSrc: fileURL, fileName: fileName },
    trapFocus: true,
    canCancel: ['escape', 'x', 'outside'],
    customClass: 'is-pdf-modal',
    onCancel: () => {
      URL.revokeObjectURL(fileURL)
    },
  })
}
