import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { ICompany, ICompanyCreate, ISudoCompanyUpdate } from '@/store/interfaces/company-model'
import { NotImplementedError } from '../error-handlers'

export const companyApi = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAll(token: string) {
    throw new NotImplementedError()
  },
  async getMulti(token: string, skip = 0, limit = 100) {
    return axios.get<ICompany[]>(`${apiUrl}/v1/super/companies/?skip=${skip}&limit=${limit}`, authConfig(token))
  },
  async search(token: string, searchString: string) {
    return axios.get<ICompany[]>(`${apiUrl}/v1/super/companies/search/${searchString}`, authConfig(token))
  },
  async create(token: string, data: ICompanyCreate) {
    return axios.post<ICompany>(`${apiUrl}/v1/super/companies/`, data, authConfig(token))
  },
  async get(token: string, companyId: number) {
    return axios.get<ICompany>(`${apiUrl}/v1/super/companies/${companyId}`, authConfig(token))
  },
  async update(token: string, companyId: number, data: ISudoCompanyUpdate) {
    return axios.put<ICompany>(`${apiUrl}/v1/super/companies/${companyId}`, data, authConfig(token))
  },
  async delete(token: string, companyId: number) {
    return axios.delete<ICompany>(`${apiUrl}/v1/super/companies/${companyId}`, authConfig(token))
  },
  async bindToCompany(token: string, companyId: number) {
    return axios.get<ICompany>(`${apiUrl}/v1/super/companies/${companyId}/bind`, authConfig(token))
  },
}
